.App {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
}

#questionnaireForm {
  display: flex;
  padding: 10px;
  flex-direction: column;
  gap: 10px;
} 

.questionnaireBox {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-left: 40px;
  padding-right: 40px;
}

.waveformContainer {
  min-width: 400px;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-self: center;
}


wave {
  width: 400px !important;
  color: black;
}

.steps {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.terms {
  display: flex;
  flex-direction: column;
  margin-left: 200px;
  margin-right: 200px;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: 50px;
  margin-right: 50px;
}

.logo {
  width: 100px;
  object-fit: contain;
}

.columns {
  display: flex;
  flex-direction: row;
}

.instructions {
  margin-right: calc(60vh - 300px);
  margin-left: 20px;
  width: 300px;
  font-size: 16px;
  padding: 10px;
  display: flex;
  gap: 10px;
  flex-direction: column;
}

.steps {
  display: flex;
  flex-direction: column;
  gap: 100px;
}